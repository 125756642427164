<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="POs">
			<b-col cols="12" xl="12">
				<div class="empty-state" v-if="MyPoObj.length === 0">
					<p>{{ this.FormMSG(10, 'No purchase orders to validate.') }}</p>
				</div>
				<div v-if="$screen.width < 992">
					<MobileViewValidatePos :pos="MyPoObj" :poFields="poFields" @validate-item="validateItem" @mobile-card-clicked="rowClicked" />
				</div>
				<div class="container-layout details-document-package p-0" v-else>
					<b-card no-body class="card-border-blue-light" v-for="oneItem in MyPoObj" :key="oneItem.depName">
						<b-row class="back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
							<b-col cols="6">
								<h2 class="d-flex align-items-center">
									<div>{{ oneItem.depName }}</div>
									<b-badge pill class="ml-2">{{ oneItem.numPoForDep }}</b-badge>
								</h2>
							</b-col>
							<b-col cols="6">
								<div class="card-header-actions">
									<b-link class="card-header-action btn-minimize text-white" v-b-toggle="oneItem.depName">
										<i class="icon-arrow-down"></i>
									</b-link>
								</div>
							</b-col>
						</b-row>
						<b-collapse visible :id="oneItem.depName">
							<b-card-body>
								<div v-for="oneUser in oneItem.users" :key="oneUser.fullName">
									<h5 class="d-flex align-items-center">
										{{ oneUser.fullName }} - {{ oneUser.functionName }}
										<b-badge pill class="ml-2">{{ oneUser.numPoForUser }}</b-badge>
									</h5>
									<b-table
										:hover="hover"
										responsive="sm"
										:items="oneUser.po"
										:fields="poFields"
										:current-page="currentPage"
										sticky-header="700px"
										:per-page="perPage"
										@row-clicked="rowClicked"
										:head-variant="hv"
										bordered
										small
									>
										<template #cell(picture)="data">
											<button
												v-if="data.item.images && data.item.images.length > 0"
												class="btn-transparent text-success"
												@click="showPicture(data.item.images)"
											>
												<component :is="getLucideIcon('Image')" :size="20" />
											</button>
										</template>
										<template v-slot:cell(validatedStatus)="data">
											<div class="d-flex flex-row w-100" style="gap: 5px">
												<div v-if="data.item.isInContract === false" class="d-flex justify-content-end" style="width: 40%">
													<button
														class="btn-transparent text-color-rhapsody-in-blue"
														v-b-tooltip.left.html
														:title="tooltipContentOvertime()"
													>
														<component :is="getLucideIcon('AlertTriangle')" color="#EA4E" :size="18" />
													</button>
												</div>
												<div v-else class="d-flex justify-content-end" style="width: 50%"></div>
												<div class="d-flex justify-content-start wrap-status">
													<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
														{{ data.item.validatedStatus }}
													</div>
												</div>
											</div>
										</template>
										<template v-slot:cell(encodedBy)="data">
											<div class="d-flex flex-row justify-content-center align-items-center">
												{{ encodedBy(data.item) }}
											</div>
										</template>
										<template v-slot:cell(val)="data">
											<b-button class="btn bg-transparent border-0 ml-1" size="sm" @click="validateItem(data.item.id)">
												<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :color="ICONS.CHECK_SQUARE.color" :size="20" />
											</b-button>
										</template>
									</b-table>
								</div>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-col>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(11, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="successModal = false"
				ok-variant="success"
				ok-only
				modal-class="mui-animation"
				:fade="false"
			>
				{{ this.FormMSG(12, 'The purchase order has been validated!') }}
			</b-modal>
		</b-row>
	</errorContainer>
</template>
<script>
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import MobileViewValidatePos from '@/components/MobileViews/ValidatePos/Main';
import { mapActions } from 'vuex';
import { classStatus } from '@/shared/global-status';
import { rendCurrency } from '~helpers';
import globalMixin from '@/mixins/global.mixin';
import { getPurchaseOrdersToValidate, validatePurchaseOrder } from '@/cruds/po-item.crud';
import moment from 'moment';

export default {
	name: 'ValidatePOs',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		MobileViewValidatePos
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			POs: [],
			MyPoObj: [
				{
					depName: '',
					numPoForDep: 0,
					users: [
						{
							fullName: '',
							numPoForUser: 0,
							po: []
						}
					]
				}
			],
			MyPO: {
				depName: '',
				numPoForDep: 0,
				users: [
					{
						fullName: '',
						numPoForUser: 0,
						po: []
					}
				]
			},

			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			isLoading: false,

			curPo: {
				id: 0,
				requestDate: '',
				type: 0,
				amount: 0,
				validated: 0,
				validatedStatus: 'Not Submited'
			}
		};
	},
	computed: {
		poFields() {
			return [
				{
					key: 'id',
					label: '#' + this.FormMSG(21, 'Id'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'poReference',
					label: this.FormMSG(987, 'PO Reference'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(20, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				this.encodedByField,
				{
					key: 'requestDate',
					label: this.FormMSG(22, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(23, 'Department'),
					formatter: (value) => {
						return value.toLowerCase();
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(24, 'Description'),
					sortable: true
				},
				{
					key: 'supplierName',
					label: this.FormMSG(25, 'Supplier'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amount',
					label: this.FormMSG(947, 'Amount (tax excl.)'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(26, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'picture',
					label: this.FormMSG(956, 'Picture'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'val',
					label: this.FormMSG(27, 'Validate'),
					formatter: (v) => v,
					class: 'text-center',
					isSlot: true
				}
			];
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				fontSize: '14px'
			};
			if (this.curPo.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curPo.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curPo.validated == 8) {
				myObj.color = 'green';
			} else if (this.curPo.validated == 4 || this.curPo.validated == 16) {
				myObj.color = 'red';
			}
			return myObj;
		},
		encodedByField() {
			if (this.useEntryForCrewPo) {
				return {
					key: 'encodedBy',
					label: this.FormMSG(30, 'Encoded By'),
					sortable: true,
					class: 'text-center',
					isSlot: true
				};
			}
		},
		useEntryForCrewPo() {
			return store.state.myProject.useEntryForCrewPo;
		}
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		encodedBy(data) {
			return `${data.encoder.name} ${data.encoder.firstName}`;
		},
		tooltipContentOvertime() {
			let message = `${this.FormMSG(29, "This PO has been sent outside the user's contract dates. You can still validate it.")}`;
			return message;
		},
		getCurrency() {
			return store.state.myProfile.currencySymbol;
		},
		async validateItem(id) {
			const action = async () => {
				const purchaseOrderId = +id;

				await validatePurchaseOrder(purchaseOrderId, '')
					.then(async (result) => {
						await this.getNotifications();
						await this.reloadData();
						this.successModal = true;
						this.fillMyPoObj();
					})
					.catch((error) => {
						console.log(error);
					});
			};
			await this.confirmModal(action, this.FormMSG(6000, 'Do you really want to validate this item?'));
		},
		getUniqueCombination(data) {
			let uniqueCombinations = new Set();
			let uniqueData = data.filter((item) => {
				let combination = `${item.departmentName}`;
				if (!uniqueCombinations.has(combination)) {
					uniqueCombinations.add(combination);
					return true;
				}
				return false;
			});
			return uniqueData;
		},
		setUserDataPO(user) {
			let data = [...user].filter(
				(item) => item.fullName === user.fullName && item.functionName === user.functionName && item.departmentName === user.departmentName
			);
			return data;
		},
		fillMyPoObj() {
			// Sort POs by department name
			this.POs.sort((a, b) => a.user.departmentName.localeCompare(b.user.departmentName));

			// Scan POs and fill MyPoObj structure
			let depName = '';
			let userTmp = {
				fullName: '',
				functionName: '',
				numPoForUser: 0,
				po: []
			};
			this.MyPoObj = [];

			if (this.POs.length > 0) {
				for (let i = 0; i < this.POs.length; i++) {
					const po = this.POs[i];
					if (depName === '') {
						// First iteration, initialize MyPoObj with first department
						depName = po.user.departmentName;
						this.MyPO.depName = depName;
						this.MyPO.numPoForDep = 0;
						this.MyPO.users = [];
						userTmp.fullName = po.fullName;
						userTmp.functionName = po.functionName;
					} else if (po.user.departmentName !== depName) {
						// Department change, store MyPO in array and start new MyPO
						// Push last user to array
						const userObj = Object.assign({}, userTmp);
						this.MyPO.users.push(userObj);

						// Push MyPO to MyPoObj
						const myPObj = Object.assign({}, this.MyPO);
						this.MyPoObj.push(myPObj);

						// Initialize new MyPO with new department
						depName = po.user.departmentName;
						this.MyPO.depName = depName;
						this.MyPO.numPoForDep = 0;
						this.MyPO.users = [];
						userTmp.fullName = po.fullName;
						userTmp.functionName = po.functionName;
						userTmp.numPoForUser = 0;
						userTmp.po = [];
					} else if (po.fullName !== userTmp.fullName) {
						// User change within the same department, push last user to MyPO
						const userObj = Object.assign({}, userTmp);
						this.MyPO.users.push(userObj);

						// Initialize new user with new fullName
						userTmp.fullName = po.fullName;
						userTmp.functionName = po.functionName;
						userTmp.numPoForUser = 0;
						userTmp.po = [];
					}

					// Add current PO to current user and current department
					this.MyPO.numPoForDep++;
					userTmp.numPoForUser++;
					const poObj = Object.assign({}, po);
					userTmp.po.push(poObj);
				}

				// Push last user to MyPO
				const userObj = Object.assign({}, userTmp);
				this.MyPO.users.push(userObj);

				// Push last MyPO to MyPoObj
				const myPObj = Object.assign({}, this.MyPO);
				this.MyPoObj.push(myPObj);
			}
		},

		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		getStatusClass(validated) {
			let value = 'not-submitted';

			if (validated === 1) {
				value = 'info';
			} else if (validated === 2) {
				value = 'pending';
			} else if (validated === 8) {
				value = 'validated';
			} else if (validated === 4 || validated === 16) {
				value = 'refused';
			}

			return value;
		},
		updateTZdataEach(poData) {
			// setting row color
			poData.statusClass = this.getStatusClass(poData.validated);
			poData.validatedStatus = this.validatedText(poData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			//poData._rowVariant = this.validatedColor(poData.validated);
			poData.validatedClass = this.validatedColor(poData.validated);
			if (poData.user.name.length == 0 && poData.user.firstName.length == 0) {
				poData.fullName = poData.email;
			} else {
				poData.fullName = poData.user.name + ' ' + poData.user.firstName;
			}
			poData.functionName = poData.functionName.toLowerCase();
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'grey';
			}
			return retval;
		},
		async reloadData() {
			this.isLoading = true;
			await getPurchaseOrdersToValidate()
				.then((records) => {
					records.forEach(this.updateTZdataEach);
					this.POs = records;
					//console.log("reloaddata2:", this.POs);
					this.fillMyPoObj();
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		poDetailLink(id) {
			return `/validatePO/${id.toString()}`;
		},
		rowClicked(item) {
			// copy item content into curPo structure.
			//   console.log("item :", item);
			store.setCurPO(item);

			const poLink = this.poDetailLink(item.id);
			//  console.log("rowclicked starting:",expLink);
			this.$router.push({
				path: poLink
			});
		},
		classStatus(validated) {
			return classStatus(validated);
		}
	},
	async created() {
		await this.reloadData();
	}
};
</script>
